import { render, staticRenderFns } from "./istEventCell.vue?vue&type=template&id=507edf10&scoped=true&"
import script from "./istEventCell.vue?vue&type=script&lang=js&"
export * from "./istEventCell.vue?vue&type=script&lang=js&"
import style0 from "./istEventCell.vue?vue&type=style&index=0&id=507edf10&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "507edf10",
  null
  
)

export default component.exports